import React, { useState } from 'react';
import LoadingSpinner from '../atoms/animations/LoadingSpinner';
import Button from '../atoms/inputs/Button';
import Modal from '../molecules/Modal';
import { toast } from 'react-toastify';

export default function RequestInviteFormModal({
  show,
  onHide,
  communityDetail,
}) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const inputStyles =
    'block w-full placeholder-slate-500  px-4 py-3 h-12 appearance-none rounded-[30px] border-0 text-base bg-grey-gradient text-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!name || !email || !city)
      return setErrorMessage('Please fill in all values');
    try {

      const baseURL = '/.netlify/functions/request-invite';
      const url = new URL(baseURL, window.location.origin);
  
      url.searchParams.append('name', name);
      url.searchParams.append('email', email);
      url.searchParams.append('city', city);
      url.searchParams.append('community_name', communityDetail.name);
      url.searchParams.append('community_id', communityDetail.db_ref);

      const response = await fetch(url, {
        method: 'POST',
      })
        .then((res) => res.json())
        .catch((err) => console.log(err.message));
      setIsLoading(false);
      onHide();
      toast('Request submitted.');
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please try again later.');
      onHide();
      setIsLoading(false);
    }
  };

  if (!show) return null;

  return (
    <Modal
      handler={onHide}
      title={`Request Invite for ${communityDetail.name}`}
    >
      <form onSubmit={handleSubmit} className="mx-auto max-w-lg px-4">
        <input
          placeholder="Name*"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className={inputStyles}
        />

        <div className="h-4 md:h-8" />

        <input
          placeholder="Email*"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className={inputStyles}
        />

        <div className="h-4 md:h-8" />

        <input
          placeholder="City*"
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          required
          className={inputStyles}
        />
        <div className="h-4 md:h-8" />
        <div className="mx-auto flex items-center justify-center h-full relative">
          <Button
            type="submit"
            label="Submit"
            id="request-invite-submit-btn"
            isLoading={isLoading}
          />
        </div>
        <div className="h-4" />
        {errorMessage && (
          <p className="text-center my-4 lg:my-6 text-highlight text-xl lg:text-2xl">
            {errorMessage}
          </p>
        )}
      </form>
    </Modal>
  );
}
