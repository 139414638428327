import React, { useRef } from 'react';
import useOnClickOutside from '../../utils/hooks/useOnClickOutside';
import useLockBodyScroll from '../../utils/hooks/useLockBodyScroll';

export default function Modal({
  handler,
  header,
  children,
  showFooter,
  title,
}) {
  const ref = useRef();
  useOnClickOutside(ref, handler);
  useLockBodyScroll();

  return (
    <>
      <div className="mx-4 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div
            ref={ref}
            className="bg-white p-6 rounded-[36px] shadow-lg relative flex flex-col w-full outline-none focus:outline-none"
          >
            {/*header*/}
            <div className="flex items-center justify-end">
              <div className="relative">
                <button
                  className="flex items-center justify-center h-8 w-8 p-1 ml-auto border-0 text-black float-right text-xl leading-none outline-none focus:outline-none"
                  onClick={handler}
                >
                  <span className="bg-transparent text-gray-700 text-4xl md:text-6xl block pb-1 outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
            </div>
            {/*body*/}
            <div className="text-center text-indigo-500 text-xl md:text-4xl font-bold font-['DM Sans'] mb-[36px]">{title}</div>
            <div className="">{children}</div>
            {/*footer*/}
            {showFooter && (
              <div className="flex items-center justify-end p-6 border-t border-solid border-gray-700 rounded-b">
                <button
                  className="rounded-md shadow bg-transparent border text-white font-medium hover:bg-black hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900  uppercase text-sm px-6 py-3  hover:shadow-lg outline-none  mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handler}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
