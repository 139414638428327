import React, { useEffect, useState } from 'react';
import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import ActivityList from '../../components/organisms/ActivityList';
import useFetchCommunities from '../../utils/hooks/useFetchCommunities';
import TrendingIcon from '../../components/atoms/icons/TrendingIcon';
import ActivityFilter from '../../components/molecules/ActivityFilter';
import { TimeBasedFilterDropdownOptions } from '../../constants/activity';
import Button from '../../components/atoms/inputs/Button';
import RequestInviteFormModal from '../../components/organisms/RequestInviteFormModal';

const showRightOnDesktop = 'lg:order-2';
const showLeftOnDesktop = 'lg:order-1';
const showTopOnMobile = 'sm:order-1';
const showBottomOnMobile = 'sm:order-2';

export default function CommunityDetailPage({ params, data }) {
  const communityName = params['name'];
  const { communities: allCommunities } = useFetchCommunities();
  const [communityDetail, setCommunityDetail] = useState(null);
  const [showRequestInviteModal, setShowRequestInviteModal] = useState(false);

  const [timeFilterValue, setTimeFilterValue] = useState(
    TimeBasedFilterDropdownOptions[1]
  );

  useEffect(() => {
    if (allCommunities.length && communityName) {
      const communityToSet = allCommunities.find(
        (comm) =>
          comm.name.toLowerCase().replaceAll(' ', '-') ===
          communityName.toLowerCase()
      );
      setCommunityDetail(communityToSet);
    }
  }, [allCommunities, communityName]);

  return (
    <BaseLayout
      mainSectionClass="z-20 relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass=" "
    >
      <SEO
        title="Elevate Your Experience with Local Happenings and Shared Interests"
        description={`Delve into the exciting world of your community and uncover a treasure trove of local events and activities.`}
      />
      {!!communityDetail && (
        <>
          <HeroContent
            communityDetail={communityDetail}
            handleRequestInviteModal={() => setShowRequestInviteModal(true)}
          />

          <div className="md:pt-16 px-8 md:px-32 justify-start items-center gap-1 md:gap-1.5 inline-flex my-[12px] md:my-[24px]">
            <TrendingIcon className="h-[18px] w-[18px] md:h-[32px] md:w-[32px]" />
            <div className="items-center text-gray-900 text-sm md:text-[32px] font-bold font-['DM Sans']">
              Activities Near Grand Rapids
            </div>
          </div>
          <div className="px-8 md:px-32 mb-4 md:mb-8">
            <ActivityFilter
              timeFilterTitle={timeFilterValue.title}
              timeFilterValue={timeFilterValue.value}
              handleSelectTimeFilter={setTimeFilterValue}
              hideCommunityFilter={true}
              isVisible={true}
            />
          </div>

          <div className="px-8 md:px-8">
            <ActivityList
              communityId={communityDetail.db_ref}
              timeFilterValue={timeFilterValue.value}
              city={'Grand Rapids'}
              isVisible={true}
              hideCommunityPills={true}
            />
          </div>
        </>
      )}

      <RequestInviteFormModal
        communityDetail={communityDetail}
        show={showRequestInviteModal}
        onHide={() => setShowRequestInviteModal(false)}
      />
    </BaseLayout>
  );
}

function HeroContent({ communityDetail, handleRequestInviteModal }) {
  return (
    <div className="mt-[40px] md:bg-grey-gradient flex flex-col px-6 lg:grid grid-cols-1 lg:grid-cols-12 gap-3 md:gap-12 items-center justify-center py-[24px] lg:py-20">
      <div
        className={`col-span-5 ${showLeftOnDesktop} ${showTopOnMobile} flex items-center justify-center sm:mb-[12px] md:mb-[30px]`}
      >
        <div className="w-full flex justify-center items-center bg-purple-gradient w-[69px] md:w-[259px] h-[69px] md:h-[259px] rounded-[69px] md:rounded-[259px]">
          <img
            src={communityDetail.image_url}
            alt="A model of the communities in the app."
            className="drop-shadow-xl w-[64px] md:w-[250px]"
          />
        </div>
      </div>
      <div className={`col-span-6 ${showRightOnDesktop} ${showBottomOnMobile}`}>
        <div className="text-center md:text-left text-[22px] font-bold font-['DM Sans'] leading-[30px] text-gray-900 md:text-6xl">
          {communityDetail?.name}
        </div>
        <div className="md:mt-[2px] lg:mt-[36px]" />
        <div class="text-center md:text-left text-gray-600 text-sm font-medium md:text-2xl md:font-normal font-['DM Sans'] leading-tight">
          {communityDetail.description}
        </div>
        <div className="mt-[16px] lg:mt-[36px] w-full" />
        <div className="">
          <Button
            onClickHandler={handleRequestInviteModal}
            label={'Request Invite'}
            buttonClasses={` text-sm md:text-[32px] font-normal font-['DM Sans'] text-white`}
            isLoading={false}
            className="w-full md:w-[235px]"
          />
        </div>
      </div>
    </div>
  );
}
